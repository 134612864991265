import { Typography, Checkbox } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import TextField from "@material-ui/core/TextField";

export default function AddEditUser(props) {
  const {
    name,
    onChangeSelect,
    style,
    user,
    admin,
    app,
    handleChange,
    handleClose,
    open, 
    heading, 
    username,
    email
  } = props;
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">User Details</DialogTitle>
        <DialogContent>
          <DialogContentText>{heading}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="name"
            value={username}
            fullWidth
          />
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            value={email}
            fullWidth
          />

          <div>
            <select style={style} onChange={onChangeSelect} name={name}>
              <option value="select">Select</option>
              <option value={user}>User</option>
              <option value={admin}>Admin</option>
            </select>
          </div>
          <br />
          <br />
          <DialogContentText>Select Apps</DialogContentText>
          {app.map((a, i) => (
            <Typography>
              <Checkbox
                checked={a.prop}
                onChange={handleChange(a.prop)}
                value={a.prop}
                color="default"
                key={i}
              />
              <span>{a.name}</span>
            </Typography>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
