import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import Pagination from "material-ui-flat-pagination";
import EditButton from "./action/EditButton";
import DeleteAlert from "./Dialog/DeleteAlert";
import "../components/Dialog/dialogcss.scss";

const useStyles = makeStyles({
  root: {
    width: "100%",
    overflowX: "auto"
  },
  table: {
    minWidth: 650
  }
});
export default function SimpleTable({
  data,
  header,
  handleRemove,
  startEditing,
  editIdx,
  handleChange,
  stopEditing,
  handleSort,
  sortDirection,
  columnToSort,
  offset,
  onClickPage,
  total
}) {
  const classes = useStyles();
  console.log("data:", data);
  return (
    <>
      <Table aria-label="simple table" className={classes.root}>
        <TableHead>
          <TableRow>
            {header.map((x, i) => (
              <TableCell key={`thc-${i}`}>
                <div onClick={() => handleSort(x.prop)}>
                  <span>{x.name}</span>
                  {columnToSort === x.prop ? (
                    sortDirection === "asc" ? (
                      <ArrowUpwardIcon />
                    ) : (
                      <ArrowDownwardIcon />
                    )
                  ) : null}
                </div>
              </TableCell>
            ))}{" "}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={row.id}>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.email}</TableCell>
              <TableCell>{row.role}</TableCell>
              <TableCell>
                <EditButton />
                <DeleteAlert />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        limit={10}
        offset={offset}
        total={total}
        onClick={handleChange}
      />
    </>
  );
}
