import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import services from "../data";
const useStyles = makeStyles({
  card: {
    maxWidth: 220,
    float: "left",
    textAlign: "center"
    ,margin: "20px"
  },
  media: {
    width: "75%",
    margin:"10px"
  },
  customImg: {
    maxHeight: "46px"
  },
  font: {
    fontSize: "1rem"
  }
});

export default function MediaCard() {
  const classes = useStyles();

  return (
    <div>
      {services.map(app => (
        <a href={app.url} rel="noopener noreferrer" target="_blank">
          <Card className={classes.card}>
            <CardContent>
              <div class="flip-box">
  <div class="flip-box-inner">
    <div class="flip-box-front">

    <img src={`${app.image}`} alt="image" className={classes.media} ></img>
        <br></br>
        {app.name}

    </div>
    <div class="flip-box-back">
      {app.description}
    </div>
  </div>
</div>

            </CardContent>
          </Card>
        </a>
      ))}
    </div>
  );
}
