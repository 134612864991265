import React, { Component } from "react";
import Box from "@material-ui/core/Box";
import GoogleLogin from "react-google-login";
import Copyright from "../components/Copyright";
import { Redirect } from "react-router-dom";

class Login extends Component {
  responseGoogle = response => {
    // console.log("response", response);
    // console.log("props", this.props);
    // console.log("allow pop-ups", response.profileObj);
    localStorage.setItem("access_token", response.accessToken);
    localStorage.setItem("name", response.profileObj.name);
    window.location.replace(process.env.PUBLIC_URL + "/home");
  };
  failureGoogle = response => {
    //console.log("fail");
    //console.log(response);
  };
  render() {
    return localStorage.getItem("access_token") ? (
      <Redirect to="/home"></Redirect>
    ) : (
      <React.Fragment>
        <div className="paper">
          <img
            src={process.env.PUBLIC_URL + "/assets/logo-hd.png"}
            className="logo"
            alt="logo"
          ></img>

          <GoogleLogin
            clientId="241736451228-1hgvt5mcse6g1fdu664cmp8lls4g93ud.apps.googleusercontent.com"
            buttonText="Login"
            onSuccess={this.responseGoogle}
            onFailure={this.failureGoogle}
            cookiePolicy={"single_host_origin"}
          />
        </div>
        <Box mt={8} class="footer">
          <Copyright />
        </Box>
      </React.Fragment>
    );
  }
}
export default Login;