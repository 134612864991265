
const fabStyle = {
    float: 'right',
    marginLeft: '20px',
    marginRight: '20px',
    marginTop: '20px',
    marginBottom:'20px'

}

const fab = {
    marginLeft: "10px"
}

const selectStyle = {
    background: "#F0F3FB",
    border: "1px solid #F0F3FB",
    borderRadius: "4px",
    padding: "10px",
    fontSize: "16px",
    color: "#3F3F3F",
    defaultValue: "Select",
    overflow: "hidden",
    width: "100%",
    marginTop:"10px"
  };
  const selectEdit = {
    background: "#F0F3FB",
    border: "1px solid #F0F3FB",
    borderRadius: "4px",
    padding: "10px",
    fontSize: "16px",
    color: "#3F3F3F",
    overflow: "hidden",
    width: "100%",
    marginTop:"10px"
  };
  
export {
    fabStyle,
    fab,
    selectStyle,
    selectEdit

}