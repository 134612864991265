import React from "react";
import { h1Style } from "./style";

const PageNotFound = () => {
  return (
    <div>
      <center>
        <h1 style={h1Style}> No page found! </h1>
      </center>
    </div>
  );
};

export default PageNotFound;