import React from "react";
import Link from "@material-ui/core/Link";

import Typography from "@material-ui/core/Typography";
const Copyright = () => {
  return (
    <Typography variant="body2" align="center">
      {"Copyright © "}
      {new Date().getFullYear()}
      {" Successive Technologies Private Limited "}
    </Typography>
  );
};

export default Copyright;
