/* eslint-disable no-undef */
import React, { Component } from "react";
import orderBy from "lodash/orderBy";
import Table from "../components/Table";
import API from "../lib/utils/api";
//import AddButton from "../components/Dialog/AddButton";

const invertDirection = {
  asc: "desc",
  desc: "asc"
};
class UserList extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      editIdx: -1,
      columnToSort: "",
      sortDirection: "desc",
      offset: 0,
      limit: 10,
      userType: "select",
      user: "group1",
      admin: "group2",
      isEnabled: false,
      app: [
        {
          name: "Keka",
          prop: true
        },
        {
          name: "Redmine",
          prop: true
        },
        {
          name: "Successive",
          prop: true
        }
      ]
    };
  }
  onChangeHandle = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
    this.setState({ isEnabled: true });
  };

  componentDidMount() {
    // API.interceptors.request.use(config => {
    //   const auth_token = localStorage.getItem("token");
    //   if (auth_token) {
    //     config.headers.Authorization = auth_token;
    //   }
    //   return config;
    // });
    //console.log("hi did mount");
    API.get(`/user`).then(res => {
      console.log("response:", res);
      const data = res.data;
      this.setState({ data });
    });
  }

  handleRemove = i => {
    this.setState(state => ({
      data: state.data.filter((row, j) => j !== i)
    }));
    // const data = this.state.data;
    // const id = data[i].originalId;
    // API.delete(`/trainee/:${id}`, { id }).then(res => console.log(res.data));
  };

  startEditing = i => {
    this.setState({ editIdx: i });
    const data = this.state.data;

    const id = data[i].originalId;
    const name = data[i].name;
    const email = data[i].email;
    API.put("/user", {
      id,
      name,
      email
    }).then(res => console.log(res.data));
  };

  stopEditing = () => {
    this.setState({ editIdx: -1 });
  };

  handleChange = (e, name, i) => {
    const { value } = e.target;
    this.setState(state => ({
      data: state.data.map((row, j) =>
        j === i ? { ...row, [name]: value } : row
      )
    }));
    const { offset, limit } = this.state;
    let skip = parseInt(offset + 10);
    let url = `/user?limit=${limit}&skip=${skip}`;
    API.get(url).then(res => {
      const data = res.data;
      this.setState({ data });
    });
    this.setState({ offset: skip });
  };

  handleSort = columnName => {
    this.setState(state => ({
      columnToSort: columnName,
      sortDirection:
        state.columnToSort === columnName
          ? invertDirection[state.sortDirection]
          : "asc"
    }));
  };

  handleClick(offset) {
    this.setState({ offset });
  }

  handleRemove = i => {
    this.setState(state => ({
      data: state.data.filter((row, j) => j !== i)
    }));
  };

  startEditing = i => {
    this.setState({ editIdx: i });
  };

  stopEditing = () => {
    this.setState({ editIdx: -1 });
  };

  handleChange = (e, name, i) => {
    const { value } = e.target;
    this.setState(state => ({
      data: state.data.map((row, j) =>
        j === i ? { ...row, [name]: value } : row
      )
    }));
  };
  handleCheckboxChange = prop => event => {
    this.setState({
      prop: this.state.app.map(a => (a.prop = event.target.checked))
    });
    //this.setState({prop: event.target.checked});
  };

  handleSort = columnName => {
    this.setState(state => ({
      columnToSort: columnName,
      sortDirection:
        state.columnToSort === columnName
          ? invertDirection[state.sortDirection]
          : "asc"
    }));
  };

  render() {
    return (
      <div>
        {/* <AddButton /> */}

        <Table
          data={orderBy(
            this.state.data,
            this.state.columnToSort,
            this.state.sortDirection
          )}
          header={[
            {
              name: "Name",
              prop: "name"
            },
            {
              name: "Email",
              prop: "email"
            },
            {
              name: "Role",
              prop: "role"
            },
            {
              name: "Action",
              prop: "action"
            }
          ]}
          handleSort={this.handleSort}
          handleRemove={this.handleRemove}
          startEditing={this.startEditing}
          editIdx={this.state.editIdx}
          stopEditing={this.stopEditing}
          handleChange={this.handleChange}
          columnToSort={this.state.columnToSort}
          sortDirection={this.state.sortDirection}
          offset={this.state.offset}
          total={100}
          onClickPage={(e, offset) => this.handleClick(offset)}
        />
      </div>
    );
  }
}

export default UserList;
