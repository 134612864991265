import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import UserList from "../admin/pages/UserList";
import PageNotFound from "../admin/pages/PageNotFound";
import Login from "../layout/Login";
import Home from "../layout/Home";

const Routes = props => {
  return (
    <Router>
      <Switch>
        <PrivateRoute path="/admin" component={UserList} />
        <PrivateRoute path="/home" component={Home} />
        <PublicRoute path="/" component={Login} />
        <Route path="*" exact={true} component={PageNotFound} />
      </Switch>
    </Router>
  );
};

export default Routes;
