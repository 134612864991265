const data = [
  
  {
    name: "Successive Email",
    url: "https://mail.google.com/a/successive.tech",
    image: "https://ssl.gstatic.com/ui/v1/icons/mail/rfr/logo_gmail_lockup_default_1x_r2.png",
    description: "Google Workspace (formerly G Suite) seamlessly and securely brings together messaging, meetings, docs, and tasks."
  },
  {
    name: "HRMS-Keka" ,
    url: "https://successivesoftware.keka.com/",
    image: "https://keka.com/static/images/logos/KekaLogoBlack.svg",
    description: " Keka is a simple and intuitive HR software unlike the traditional HR systems."
  },
  {
    name: "Project Management",
    url: "https://redmine.successive.work/",
    image: "assets/redmine.png",
    description: "Redmine is a flexible project management web application. Project management and timesheets are the two primary usages for this tool."
  },

  {
    name: "Bitbucket",
    url: "https://bitbucket.org/successivesoftware",
    image: "assets/bitbucket.png",
    description: "Bitbucket is more than just Git code management. Bitbucket gives teams one place to plan projects, collaborate on code, test, and deploy."
  },
  {
    name: "SQUADHUB",
    url: "https://development.successive.work/login",
    image: "assets/squadhub.png",
    description: "Technology Knowledge Base - A hub for all technical documents"
  },
  
  {
    name: "Sharepoint Online",
    url: "https://successivesoftwarepvtltd.sharepoint.com/",
    image: "assets/sharepoint.png",
    description: "SharePoint empowers teamwork with dynamic and productive team sites for every project team, department, and division. Share files, data, news, and resources."
  },
  {
    name: "Zoom",
    url: "https://successive.zoom.us/",
    image: "assets/zoom.png",
    description: "Zoom is the leader in modern enterprise video communications, with an easy, reliable cloud platform for video and audio conferencing, chat, and webinars across mobile, desktop, and room systems."
  },
  {
    name: "Slack",
    url: "https://successivetech.slack.com/",
    image: "assets/slack.png",
    description: "Slack is a new way to communicate with your team. It's faster, better organised and more secure than email."
  },
  {
    name: "ATS",
    url: "https://hrms.successive.work/",
    image: "https://successive.tech/wp-content/uploads/2020/01/logo.png",
    description: "Applicant Tracking System is used for Tracking new hire request."
  }
  ,
  {
    name: "PMS",
    url: "https://pms.successive.work/",
    image: "https://successive.tech/wp-content/uploads/2020/01/logo.png",
    description: "Performance Management system to track your performanance and track one to one meetings and year end performance review."
  }
  ,
  {
    name: "PMO",
    url: "https://pmo.successive.work/login",
    image: "https://successive.tech/wp-content/uploads/2020/01/logo.png",
    description: "Resource Management tool to view the resource utilization, allocation of resources in the projects and resource pyramid."
  },
  {
    name: "Resume Builder/Talent Profiler",
    url: "https://talentprofiler.successive.work/",
    image: "https://successive.tech/wp-content/uploads/2020/01/logo.png",
    description: "Resume Builder is used to creating resumes for sharing the resumes with client."
  },
    {
    name: "Meeting Room",
    url: "https://meeting.successive.work/",
    image: "https://successive.tech/wp-content/uploads/2020/01/logo.png",
    description: "Meeting rooms can be booked using this portal for Noida Office."
  }  ,
  {
    name: "ESSL - IT Team Only",
    url: "https://attendence.successive.work/iclock/",
    image: "https://successive.tech/wp-content/uploads/2020/01/logo.png",
    description: "Biometric Solution"
  }
  ,
  {
    name: "Solution Hub",
    url: "http://demo.successive.work/",
    image: "https://successive.tech/wp-content/uploads/2020/01/logo.png",
    description: ""
  }
  ,
  {
    name: "IT Asset Management",
    url: "https://itportal.successive.work/",
    image: "https://snipeitapp.com/img/logos/snipe-it-logo-xs.png",
    description: "IT Asset Management tool to manage the allocation of assets and audit records. You can view the allocated or requestable asset details here."
  }
  ,
  {
    name: "TestLink - VPN Required",
    url: "http://testlink.successive.work/login.php",
    image: "https://avatars.githubusercontent.com/u/10183803?v=4",
    description: "Test case management tool for QA Team"
  },

  {
    name: "LMS Portal",
    url: "https://learn.successive.work/",
    image: "https://learn.successive.work/theme/image.php?theme=boost&component=core&rev=1616168013&image=moodlelogo_grayhat",
    description: "Online Learning and Assesment plateform."
  },


  {
    name: "CI/CD",
    url: "https://ci.successive.work",
    image: "assets/jenkins.png",
    description: "enkins – an open source automation server which enables developers around the world to reliably build, test, and deploy their software."
  },

  {
    name: "SonarQube",
    url: "https://sonar.successive.work/",
    image: "assets/sonarqube.png",
    description: "SonarQube empowers all developers to write cleaner and safer code."
  },

  {
    name: "Genie on Web",
    url: "https://web.leena.ai/?clientId=JLP5bOZdxp2VYov1bHKl8",
    image: "https://s3.chatteron.io/chatteron.io/public/bots/61f83bcb325390ab2b3425f8/attachment-EeW83Jr15F/unnamed%20(2).png",
    description: "Talk to Genie on Web for any help related to HR,IT,Admin and Finance."
  },
  {
    name: "Genie on WhatsApp powered by Leena AI",
    url: "https://api.whatsapp.com/send?text=successive&phone=+15129829656",
    image: "https://s3.chatteron.io/chatteron.io/public/bots/61f83bcb325390ab2b3425f8/attachment-EeW83Jr15F/unnamed%20(2).png",
    description: "Talk to Genie on Whatsapp for any help related to HR,IT,Admin and Finance."
  },
  {
    name: "DAN Billing Portal",
    url: "https://danbilling.successive.work/",
    image: "https://successive.tech/wp-content/uploads/2020/01/logo.png",
    description: "This portal is used to generate the DAN Billing Data and it help to speed things up."
  },
  


];
export default data;