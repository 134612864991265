import React from "react";
//import Header from "../components/Header/Header";

const DashboardLayout = ({ children, ...rest }) => {
  return (
    <div className="page page-dashboard">
      <div className="navbar">{/* <Header /> */}</div>
      <div className="main">{children}</div>
    </div>
  );
};

export default DashboardLayout;
