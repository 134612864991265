import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import NavBar from "../components/NavBar";
import Boxes from "../components/Boxes";
import Box from "@material-ui/core/Box";
import Copyright from "../components/Copyright";
import { Redirect } from "react-router-dom";
const useStyles = makeStyles(theme => ({
  root: {
    
  },
  borderShadow: {
    padding: "15px",
    display: "flex",
    alignItems: "center"
  },
  noShadow: {
    boxShadow: "none",
    background: "transparent"
  }
}));

export default function PaperSheet(props) {
  console.log("props", props);
  const classes = useStyles();

  return localStorage.getItem("access_token") ? (
    <React.Fragment>
      <NavBar></NavBar>
      <Grid container className={classes.root}>
        <Grid item xs={12} className={[classes.borderShadow, "myClass"]}>
          <Paper className={classes.noShadow}>
            <Boxes></Boxes>
          </Paper>
        </Grid>
      </Grid>
      <Box mt={8} class="footer">
        <Copyright />
      </Box>
    </React.Fragment>
  ) : (
    <Redirect to="/"></Redirect>
  );
}
