import React, { Component } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import { GoogleLogout } from "react-google-login";

class NavBar extends Component {
  constructor() {
    super();
    let googleToken = localStorage.getItem("access_token");
    console.log("googleToken", googleToken);
  }
  logout = () => {
    localStorage.removeItem("name");
    localStorage.removeItem("access_token");
    window.location.replace(process.env.PUBLIC_URL);
  };
  render() {
    return (
      <div className="root">
        <AppBar position="static">
          <Toolbar>
            <Typography className="title">
              <img
                src={process.env.PUBLIC_URL + "/assets/logo-3.png"}
                alt="logo"
              ></img>
            </Typography>
            <MenuItem>
              <Typography component="p">
                Welcome {localStorage.getItem("name")}
              </Typography>
            </MenuItem>
            <GoogleLogout
              clientId="241736451228-1hgvt5mcse6g1fdu664cmp8lls4g93ud.apps.googleusercontent.com"
              buttonText="Logout"
              onLogoutSuccess={this.logout}
            ></GoogleLogout>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}
export default NavBar;
