import React, { Component } from "react";
import Edit from "@material-ui/icons/Edit";
import Fab from "@material-ui/core/Fab";
import AddEditUser from "../Dialog/AddEditUser";
import { selectEdit } from "../Dialog/style";

export default class EditButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      app: [
        {
          name: "Keka",
          prop: true
        },
        {
          name: "Redmine",
          prop: true
        },
        {
          name: "Successive",
          prop: true
        }
      ],
      userType: "select",
      user: "group1",
      admin: "group2",
      isEnabled: false,
      heading: "Edit user details",
      username: "Varsha",
      email: "varsha.rana@successive.tech"
    };
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  //check box

  handleChange = prop => event => {
    this.setState({
      prop: this.state.app.map(a => (a.prop = event.target.checked))
    });
    //this.setState({prop: event.target.checked});
  };
  render() {
    const { userType, isEnabled, user, admin, app, open, heading } = this.state;
    const { username , email, role } = this.props;

    return (
      <>
        <Fab onClick={this.handleClickOpen} size="small" color="primary">
          <Edit />
        </Fab>
        <AddEditUser
          handleChange={this.handleChange}
          handleClickOpen={this.handleClickOpen}
          handleClose={this.handleClose}
          open={open}
          app={app}
          onChangeSelect={this.onChangeHandle}
          type={userType}
          isEnabled={isEnabled}
          user={user}
          admin={admin}
          style={selectEdit}
          heading={heading}
          username={username}
          email={email}
          role={role}
        />
      </>
    );
  }
}
